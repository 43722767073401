<template>
    <div>
        <table class="w-full text-sm">
            <tr>
                <th class="border-b pb-3">Konto</th>
                <th class="border-b pb-3">Kontonamn</th>
                <th class="border-b pb-3" align="right">Ingående balans</th>
                <th class="border-b pb-3" align="right">Period</th>
                <th class="border-b pb-3" align="right">Utgående balans</th>
            </tr>
            <tr v-for="item in resultsData" :key="item.account" @click="accountSelected(item)" class="hover:bg-gray-100 cursor-pointer transition-colors">
                <td class="p-1" width="120">
                    <div class="flex items-center">
                        <p v-if="item.oldAccount !== undefined">{{ item.oldAccount }} <i class="fal fa-arrow-right mx-1" /> {{ item.account }}</p>
                        <p v-else>
                            {{ item.account }}
                        </p>
                        <i v-if="!accountsData.includes(item.account) && !item.oldAccount" class="fa-solid fa-circle-exclamation text-red-500 ml-1 fa-fw" />
                    </div>
                </td>
                <td class="p-1" align="left">{{ item.name }}</td>
                <td class="p-1" align="right">{{ item.period | swedishNumberFormat }}</td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    props: {
        resultsData: {
            type: Array,
            default: () => [],
        },
        accountsData: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        accountSelected(item) {
            this.$emit("select", {
                account: item.account,
                oldAccount: item?.oldAccount,
                name: item.name,
            });
        },
    },
};
</script>
